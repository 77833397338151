import {useQuery} from "react-query";
import {getUserMapLayers} from "../../../api/project/projectApi";
import {IProject} from "../../../models/IProject";
import {showDate} from "../../../helpers/showDate";
import {IUserMapLayer} from "../../../models/IUserMapLayer";
import {GridColumns} from "@mui/x-data-grid";
import {Stack} from "@mui/material";
import UserMapLayersTableActionCell from "./UserMapLayersTableActionCell";

export const useGetDataForUserMapLayersTable = (schema: string) => {
  const {data: layers, refetch} = useQuery(['userMapLayers'], getUserMapLayers);

  const rows: any = layers ? layers.data.map((layer: IUserMapLayer) => ({
    id: layer.id,
    name: layer.name,
    color: layer.color,
    actions: layer.id
  })) : [];

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      type: 'number'
    },
    {
      field: 'name',
      headerName: 'Название',
      width: 200,
      type: 'string'
    },
    {
      field: 'color',
      headerName: 'Цвет',
      width: 200,
      type: 'string',
      renderCell: (params: any) => (
        <Stack
          p={2}
          sx={{
            backgroundColor: params.value,
            borderRadius: "5%"
          }}>
          {params.value}
        </Stack>
      ),
    },
    {
      field: 'actions',
      headerName: 'Действия',
      width: 200,
      type: 'string',
      renderCell: (params: any) => <UserMapLayersTableActionCell params={params} refetch={refetch} schema={schema}/>,
    }
  ]

  return {
    rows, columns
  }
}