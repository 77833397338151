import React, { FC } from 'react';
import styles from './cardHeader.module.scss'
import InfoButton from "../InfoButton/InfoButton";

const CustomCardHeader: FC<any>  = ({title, onInfoClick}) => {

  return (
    <div className={styles.cartHeader_wrapper}>
      <p>{title}</p>
      <InfoButton onClick={onInfoClick}/>
    </div>
  );
};
export default CustomCardHeader;