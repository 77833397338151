import React, {FC, useCallback, useState} from 'react';
import {GridRenderCellParams} from "@mui/x-data-grid";
import Icon from "../../../components/UI/Icons/Icon";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import {RgbStringColorPicker} from "react-colorful";
import LoadingButton from "@mui/lab/LoadingButton";
import {useCustomMutation} from "../../../api/useCustomMutation";
import {UPDATE_USER_MAP_LAYER_URL} from "../../../api/urls";
import {toast} from "react-toastify";

interface Props {
  params: GridRenderCellParams,
  refetch: () => void,
  schema: string
}

const UserMapLayersTableActionCell: FC<Props> = ({params, refetch, schema}) => {
  const [open, setOpen] = useState<"edit" | "delete" | undefined>(undefined);
  const mutation = useCustomMutation()

  const {handleSubmit, register, reset, setValue} = useForm({
    defaultValues: {
      name: params.row.name,
      color: params.row.color,
    }
  })

  const openEdit = useCallback(() => setOpen("edit"), [])
  const openDelete = useCallback(() => setOpen("delete"), [])

  const handleClose = useCallback(() => {
    setOpen(undefined);
    reset()
  }, [])

  const onSaveEdit = (data: {name: string, color: string})=> {
    mutation.mutateAsync({
      method: 'patch',
      data: {
        ...data,
        id: params.row.id,
        schema: schema,
      },
      url: UPDATE_USER_MAP_LAYER_URL + params.row.id
    })
      .then(() => {
        toast.success("Обновления сохраненны, обновите страницу, чтобы увидеть их на карте");
        reset();
        refetch()
        handleClose()
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }

  const onDelete = ()=> {
    mutation.mutateAsync({
      method: 'delete',
      url: UPDATE_USER_MAP_LAYER_URL + params.row.id + `?schema=${schema}`
    })
      .then(() => {
        toast.success("Слой удалён");
        refetch()
        handleClose()
      })
      .catch(({ response }) => {
        toast.error(response?.data?.message)
      })
  }

  return (
    <>
      <IconButton onClick={openEdit}>
        <Icon icon={'edit'} color={'#ff5b00'}/>
      </IconButton>

      <IconButton onClick={openDelete}>
        <Icon icon={'delete'} color={'#ff0000'}/>
      </IconButton>

      <Dialog open={open === "edit"} onClose={handleClose}>
        <DialogTitle>
          Обновление слоя
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2} padding={1}>
            <TextField {...register('name')} label={'Название'}/>

            <Stack direction="row" spacing={2}>
              <p>Цвет</p>

              <RgbStringColorPicker
                onChange={(color) => setValue("color", color)}
                color={params.row.color}
              />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton onClick={handleClose} autoFocus>
            Отмена
          </LoadingButton>
          <LoadingButton onClick={handleSubmit(onSaveEdit)}>
            Обновить
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog open={open === "delete"} onClose={handleClose}>
        <DialogTitle>
          Удаление слоя
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2} padding={1}>
            <h3>
              Вы уверены, что хотите удалить слой "{params.row.name}"?
            </h3>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton onClick={handleClose} autoFocus>
            Отмена
          </LoadingButton>
          <LoadingButton onClick={onDelete}>
            Удалить
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserMapLayersTableActionCell;