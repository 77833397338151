import React, {useState} from 'react';
import {useCustomMutation} from "../../api/useCustomMutation";
import {ls_setProjectInfo} from "../../helpers/localStorage";
import {toast} from "react-toastify";
import {CREATE_USER_MAP_LAYER_URL, IMPORT_RGIS_URL} from "../../api/urls";
import {Card, CardActions, CardContent, CardHeader, Stack, TextField} from "@mui/material";
import styles from "../ImprotVectorsRgis/importPlacesAndGravesFromProject.module.scss";
import SelectProject from "../SelectProject/SelectProject";
import {LoadingButton} from "@mui/lab";
import {useForm} from "react-hook-form";

const ImportUserMapLayers = () => {
  const mutation = useCustomMutation()
  const {register, handleSubmit} = useForm()

  const [project, setProject] = useState<any>()

  const onProjectClick = (project: any) => {
    ls_setProjectInfo(project)
    setProject(project)
  }

  const inputLabelProps = {shrink: true}
  const size = 'small'
  const type = 'file'

  const onSubmit = async (data: any) => {
    try {
      console.log(data)
      const formData = new FormData()

      formData.set('schema', project.schema)
      formData.set('file', data.file[0])

      const res = await mutation.mutateAsync({
        method: 'post',
        url: CREATE_USER_MAP_LAYER_URL,
        data: formData
      })

      toast.success(res.data.message)

    } catch (e: any) {
      console.log(e);

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return (
    <Card elevation={4} className={styles.card}>
      <CardHeader title={'Загрузка пользовательских слоёв карты'}/>

      <CardContent>
        <Stack spacing={2}>

          <SelectProject onProjectClick={onProjectClick}/>
          {project &&
            <h2>{project ? project.name : 'Выберите проект'}</h2>
          }

          <TextField
            {...register('file', {required: true})}
            size={size}
            type={type}
            label={'Слои'}
            InputLabelProps={inputLabelProps}
          />
        </Stack>
      </CardContent>

      <CardActions>
        <Stack direction={"row"} justifyContent={"space-between"} width={'100%'}>
          <LoadingButton
            loading={mutation.isLoading}
            variant={'outlined'}
            onClick={handleSubmit(onSubmit)}
          >
            Загрузить
          </LoadingButton>
        </Stack>
      </CardActions>
    </Card>
  )
};

export default ImportUserMapLayers;