import React, {FC, useState} from 'react';
import {Card, CardActions, CardContent, FormControlLabel, FormLabel, Radio, RadioGroup, Stack} from "@mui/material";
import styles from "./CalculatePlacesTypes/calculatePlacesTypes.module.scss";
import CustomCardHeader from "./UI/MyCardHeader/CustomCardHeader";
import {algorithmsTitles} from "../utils/utils";
import SelectProject from "./SelectProject/SelectProject";
import {LoadingButton} from "@mui/lab";
import {toast} from "react-toastify";
import {useCustomMutation} from "../api/useCustomMutation";
import Icon from "./UI/Icons/Icon";
import {ROTATE_PHOTOS_ON_CROC_TO_ALBUM_URL, ROTATE_PHOTOS_ON_CROC_TO_BOOK_URL} from "../api/urls";

interface Props {
  entity: "graves" | "places";
}

const RotateAllPhotos: FC<Props> = ({entity}) => {
  const mutation = useCustomMutation()

  const [project, setProject] = useState<any>()
  const [direction, setDirection] = useState<"left" | "right">("left");

  const onProjectClick = (project: any) => {
    setProject(project)
  }

  const onInfoClick = () => {
    toast(
      <>
        Переворачиваем фото в {entity === "graves" ? "книжную" : "альбомную"} ориентацию, если у него другая.
      </>
    )
  }

  const onSubmit = async () => {
    try {
      if (!project) {
        toast.error("Проект не выбран");
        return;
      }

      const url = entity === "places" ? ROTATE_PHOTOS_ON_CROC_TO_ALBUM_URL : ROTATE_PHOTOS_ON_CROC_TO_BOOK_URL

      await mutation.mutateAsync({
        method: 'post',
        url: url + `?direction=${direction}&imageFolder=${project.imageFolder}`
      })

      toast.success("Фотографии были успешно повёрнуты");
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return (
    <Card elevation={6} className={styles.card}>

      <CustomCardHeader title={entity === "places" ? algorithmsTitles.RotateToAlbum : algorithmsTitles.RotateToBook}
                        onInfoClick={onInfoClick}/>

      <CardContent>
        <div>
          <h3>{project?.name}</h3>
        </div>

        <Stack>
          <FormLabel id="demo-radio-buttons-group-label">Направление</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={direction}
            onChange={(_, v) => {
              if (v === "left" || v === "right") setDirection(v)
            }
            }
          >
            <FormControlLabel
              value="left"
              control={<Radio/>}
              label={<Icon icon={"rotate"} className={styles.icon}/>}
            />
            <FormControlLabel
              value="right"
              control={<Radio/>}
              label={
                <Icon
                  icon={"rotate"}
                  className={styles.mirror_icon}
                />
              }
            />
          </RadioGroup>
        </Stack>
      </CardContent>

      <CardActions>
        <Stack direction={"row"} spacing={3}>
          <SelectProject onProjectClick={onProjectClick}/>

          <LoadingButton
            variant={'contained'}
            loading={mutation.isLoading}
            onClick={onSubmit}
          >
            Начать проверку
          </LoadingButton>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default RotateAllPhotos;