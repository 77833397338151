import React from 'react';
import {ls_getProjectInfo} from "../../../helpers/localStorage";
import {useGetDataForUserMapLayersTable} from "./useGetDataForUserMapLayersTable";
import styles from "../../_admin/projects/projects.module.scss";
import Loader from "../../../components/UI/Loader/Loader";
import {Stack} from "@mui/material";
import {DataGrid, ruRU} from "@mui/x-data-grid";

const UserMapLayersPage = () => {
  const project = ls_getProjectInfo();
  const {rows, columns} = useGetDataForUserMapLayersTable(project.schema)

  if (!rows) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader/>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <Stack spacing={1}>
        <div className={styles.title}>
          <h1>Пользовательские слои карты</h1>
        </div>

        <div className={styles.table_wrapper}>
          <DataGrid
            columns={columns}
            rows={rows}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
      </Stack>
    </div>
  );
};

export default UserMapLayersPage;