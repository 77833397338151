import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const validateDatesInObject = (formData: any, dateKeys: string[]) => {

  for (const [key, value] of Object.entries<any>(formData)) {
    if (value === '') {
      formData[key] = null
    }

    if (dateKeys.includes(key) && value) {
      const data = replaceDate(value)

      if (dayjs(data).isValid()) {
        formData[key] = dayjs(data, {utc: true}).utc().toISOString()
      } else if (value === '') {
        formData[key] = null;
      } else {
        delete formData[key]
      }
    }
  }
}

const replaceDate = (value: string) => {
  if(value.split(':')[0].includes('-')) {
    return value
  }

  const array = value.split('.')
  return array[2] + '-' + array[1] + '-' + array[0]
}
