import React, {FC, useEffect} from 'react';
import {MapContainer, ScaleControl} from 'react-leaflet';
import styles from './mapLayout.module.scss';
import ProjectMap from './ProjectMap/ProjectMap'
import {Box, Stack, Typography} from '@mui/material';
import MovePlaceButtons from "../../../components/MapComponents/CustomButtons/movePlaceButtons/movePlaceButtons";
import {SCOPES} from "../../../helpers/permissions/permissionsMaps";
import PermissionsGate from "../../../helpers/permissions/PermissionsGate";
import {useRecoilState} from "recoil";
import {mapStateAtom} from "../../../store/mapStore";
import useMapLayout from "./useMapLayout";
import MySpeedDial from "../../../components/UI/MySpeedDial/MySpeedDial";
import Icon from "../../../components/UI/Icons/Icon";
import CustomCreatePlace from "../../../components/MapComponents/CustomButtons/CustomEdit/CustomCreatePlace";
import ImportPlacesAndSectionsModal from "./MapLayoutModal/ImportPlacesAndSectionsModal";
import TaskOrNoteModal from "./MapLayoutModal/TaskOrNoteModal";
import PlaceInfoModal from "./MapLayoutModal/PlaceInfoModal";
import SectionsNumbersModal from "./MapLayoutModal/SectionsNumbersModal";
import SectionModal from "./MapLayoutModal/SectionModal";
import GravesInfoModal from "./MapLayoutModal/GravesInfoModal";
import CustomButtonsContainer from "../../../components/MapComponents/CustomButtons/CustomButtonsContainer";

const initialCenter: any = [55.763441, 37.592812];

const MapLayout: FC = () => {
  const [mapState] = useRecoilState(mapStateAtom)

  const {
    projectInfo,
    mapData,
    memoizedGravesWithoutPhoto,
    memoizedBorders,
    userMapLayers,
    memoizedPlacesWithoutPhotos,
    memoizedPlacesWithoutSections,
    placesWithoutPhotoOnCroc,
    memoizedPlannedPlaces,
    gravesWithoutPhotoOnCroc,
    memoizedGravesWithPhoto,
    memoizedGravesWithoutPlace,
    memoizedPlacesWithPhotos,
    memoizedSections,
    notExisingPhotos,
    memoizedTasks,
    memoizedNotes,
    onAddSection,
    loadMapData,
    onShowInfo,
    onCloseTaskOrNote,
    onCloseSelectFinger,
    onClose,
    onCopy,
    onDeleteTaskOrNote,
    loadPlaceInfo,
    onShowSection,
  } = useMapLayout()

  useEffect(() => {
    loadPlaceInfo()
  }, [mapState.preSelectedObject])

  useEffect(() => {
    loadMapData()
  }, [mapState.selectedSectionsNumbers])

  useEffect(() => {
    if (mapState.needReFetch) {
      loadMapData()
    }
  }, [mapState.needReFetch])

  if (!projectInfo) {
    return <h2>Загрузите проект</h2>
  }

  return (
    <Box className={styles.wrapper}>

      <CustomCreatePlace/>

      <Stack direction={'row'} alignItems={'center'} spacing={1} padding={1}>
        <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
          <span className={styles.hint}/>
          <Typography> - ограды/могилы без фото</Typography>
        </PermissionsGate>

        <div className={styles.info_btn_animation} onClick={onShowInfo}>
          <Icon icon={"info"}/>
        </div>
      </Stack>

      <TaskOrNoteModal
        onDeleteTaskOrNote={onDeleteTaskOrNote}
        onCloseTaskOrNote={onCloseTaskOrNote}
      />

      <ImportPlacesAndSectionsModal onCloseSelectFinger={onCloseSelectFinger}/>

      <PlaceInfoModal
        onClose={onClose}
        onCopy={onCopy}
      />

      <GravesInfoModal
        onClose={onClose}
        onCopy={onCopy}
      />

      <SectionModal onClose={onClose}/>

      <MySpeedDial>
        <SectionsNumbersModal
          onAddSection={onAddSection}
          onShowSection={onShowSection}
          memoizedSections={memoizedSections}
        />
      </MySpeedDial>

      <MapContainer
        preferCanvas
        className={styles.leaflet_container}
        center={initialCenter}
        zoom={12}
        doubleClickZoom={false}
        keyboard={false}
      >
        <ProjectMap
          gravesWithoutPhotoOnCroc={gravesWithoutPhotoOnCroc || []}
          placesWithoutPhotoOnCroc={placesWithoutPhotoOnCroc || []}
          borders={memoizedBorders || []}
          sections={memoizedSections || []}
          placesWithPhotos={memoizedPlacesWithPhotos || []}
          placesWithoutPhotos={memoizedPlacesWithoutPhotos || []}
          placesWithoutSections={memoizedPlacesWithoutSections || []}
          gravesWithPhoto={memoizedGravesWithPhoto || []}
          gravesWithoutPhoto={memoizedGravesWithoutPhoto || []}
          showObjectsWithoutPhotoOnCroc={notExisingPhotos.showOnMap}
          sectionsCenters={mapData?.sectionsCenters || []}
          onAddSection={onAddSection}
          plannedPlaces={memoizedPlannedPlaces || []}
          tasks={memoizedTasks || []}
          notes={memoizedNotes || []}
          gravesWithoutPlace={memoizedGravesWithoutPlace || []}
          userMapLayers={userMapLayers || []}
        />

        <ScaleControl position={'topright'}/>

        <CustomButtonsContainer/>

        <PermissionsGate
          scopes={
            mapState.preSelectedObject?.type === 'section' ?
              [SCOPES.superAdmin] :
              [SCOPES.admin, SCOPES.superAdmin, SCOPES.superUser, SCOPES.editorUser]}
        >
          <MovePlaceButtons/>
        </PermissionsGate>
      </MapContainer>
    </Box>
  );
};

export default React.memo(MapLayout);