import React, {FC, useState} from 'react';
import {useCustomMutation} from "../../api/useCustomMutation";
import {useRecoilState} from "recoil";
import {wrongPlacesRgisAtom} from "../../store/wrongPlacesRgisStore";
import {notOkCandidatesOfSyncAtom} from "../../store/notOkCandidatesOfSyncStore";
import {useForm} from "react-hook-form";
import {ls_setProjectInfo} from "../../helpers/localStorage";
import {toast} from "react-toastify";
import Loader from "../UI/Loader/Loader";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField
} from "@mui/material";
import styles from "./syncSchemas.module.scss";
import SelectProject from "../SelectProject/SelectProject";
import {LoadingButton} from "@mui/lab";
import ShowNotOkCandidatesOfSync from "./NotOkCandidatesOfSync/showNotOkCandidatesOfSync";
import ShowWrongPlacesRgis from "./ShowWrongPlacesRgis/ShowWrongPlacesRgis";
import {MERGE_RGIS_URL, SAVE_RGIS_FILE_URL, WRITE_USER_HISTORY_URL} from "../../api/urls";
import {useQuery} from "react-query";
import {checkExistingRgisFile} from "../../api/project/projectApi";
import PermissionsGate from "../../helpers/permissions/PermissionsGate";
import {SCOPES} from "../../helpers/permissions/permissionsMaps";
import {currentProjectAtom} from "../../store/projectStore";
import {algorithmsTitles} from "../../utils/utils";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";

interface Props {
  loading: boolean,
  needShowSelect?: boolean,
  type: "with_responsible" | "new_2024" | "old_2023"
}

interface FormForSync {
  schema: string
  mergeOnCopy?: string
  onlyUpdate?: string
  File?: string
}

interface FormForSave {
  File: string
}

const SyncSchemaRgis: FC<Props> = ({loading, needShowSelect = true, type}) => {
  const mutation = useCustomMutation()
  const [wrongPlacesRgis, setWrongPlacesRgis] = useRecoilState(wrongPlacesRgisAtom)
  const [notOkCandidates, setNotOkCandidates] = useRecoilState(notOkCandidatesOfSyncAtom)
  const {register, handleSubmit} = useForm<FormForSync>()
  const {register: fileRegister, handleSubmit: fileSubmit} = useForm<FormForSave>()
  const inputLabelProps = {shrink: true}
  const [currentProject, setCurrentProject] = useRecoilState(currentProjectAtom)
  const {data: exist} = useQuery(['check-file', currentProject?.schema], () => checkExistingRgisFile(currentProject?.schema));
  const [open, setOpen] = useState<'statistic' | 'notOkCandidates' | 'wrongPlaces' | 'saveFile' | undefined>(undefined)

  const onProjectClick = (project: any) => {
    ls_setProjectInfo(project)
    setCurrentProject(project)
  }

  const onNotOkCandidatesOpen = () => setOpen('notOkCandidates')
  const onWrongPlacesOpen = () => setOpen('wrongPlaces')
  const onSaveFileOpen = () => setOpen('saveFile')
  const onClose = () => setOpen(undefined)
  const onSaveFile = async (form: FormForSave) => {
    try {
      const formData = new FormData()

      if (!currentProject) {
        toast.error('Нет проекта')
        return
      }

      formData.append('file', form.File[0])
      formData.append('schema', currentProject.schema)

      const {data} = await mutation.mutateAsync({
        method: 'post',
        data: formData,
        url: SAVE_RGIS_FILE_URL
      })

      toast.success(data.message)

      onClose()
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onSubmit = async (form: FormForSync, onlyCheck = false) => {
    try {
      if (!form.File && !exist?.data) {
        toast.error('К сожелению невозможно провести совмещение, так как нет файла с ргис данными')
        return
      }

      if (!currentProject) {
        toast.error('Нет проекта')
        return
      }

      const formData = new FormData()

      formData.set('schema', currentProject.schema)
      formData.set('onlyCheck', onlyCheck ? 'true' : 'false')

      if (form.File) {
        formData.set('file', form.File[0])
      }

      formData.set('mergeOnCopy', form.mergeOnCopy + '')
      formData.set('onlyUpdate', form.onlyUpdate + '')
      formData.set('type', type)

      setWrongPlacesRgis([])
      setNotOkCandidates([])

      let changesDescription = {
        action: 'algorithm',
        objectId: currentProject.id,
        data: [{algorithmName: 'SyncSchemaRgis'}]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: currentProject.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      const {data} = await mutation.mutateAsync({
        method: 'post',
        url: MERGE_RGIS_URL,
        data: formData
      })

      if (data.wrongPlacesRgis) {
        toast.error(data.message);
        setWrongPlacesRgis(data.wrongPlacesRgis)
        return;
      }

      if (data.notOkCandidates?.length) {
        setNotOkCandidates(data.notOkCandidates)
      }

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
        , {autoClose: 15000})
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        {type === "with_responsible" ? "Проверка и совмещение РГИС выгрузки без телефонов и прочего" : "Проверка с совмещение РГИС выгрузки с телефонами и прочим"}
      </>
    )
  }

  if (loading) {
    return <Loader/>
  }

  return (
    <Card elevation={4} className={styles.card}>

      <CustomCardHeader
        title={type === "with_responsible" ? algorithmsTitles.SyncSchemaRgisWithResponsible : algorithmsTitles.SyncSchemaRgisWithoutResponsible}
        onInfoClick={onInfoClick}/>

      <CardContent>
        <Stack spacing={2}>

          <h2>{currentProject ? currentProject.name : 'Выберите проект'}</h2>
          {
            needShowSelect &&
            <SelectProject onProjectClick={onProjectClick} needFirstSelect={false}/>
          }

          <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>

            <FormControlLabel
              control={<Checkbox defaultChecked {...register('mergeOnCopy')} />}
              label="Совместить на копии"
              style={{marginTop: 0}}
            />

            <FormControlLabel
              control={<Checkbox {...register('onlyUpdate')} />}
              label="Не вставлять не найденные могилы и ограды"
              style={{marginTop: 0}}
            />

          </PermissionsGate>

          <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
            <TextField
              {...register('File', {required: false})}
              size={'small'}
              type={'file'}
              label={'Ргис данные'}
              InputLabelProps={inputLabelProps}
            />
          </PermissionsGate>

        </Stack>

      </CardContent>

      <CardActions>
        <Stack spacing={1}>
          <Stack spacing={1} direction={'row'}>
            <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>

              <LoadingButton
                loading={mutation.isLoading}
                variant={'contained'}
                onClick={handleSubmit(async (data) => await onSubmit(data))}
              >
                Совместить
              </LoadingButton>

            </PermissionsGate>

            <LoadingButton
              loading={mutation.isLoading}
              variant={'contained'}
              onClick={handleSubmit(async (data) => await onSubmit(data, true))}
            >
              Проверить ошибки
            </LoadingButton>
          </Stack>

          <PermissionsGate scopes={[SCOPES.admin, SCOPES.superAdmin]}>
            <Button variant={'text'} onClick={onSaveFileOpen}>
              Загрузить файл с ргис данными
            </Button>
          </PermissionsGate>

          {notOkCandidates.length ?
            <>
              <Button onClick={onNotOkCandidatesOpen}>
                Посмотреть не совпадения
              </Button>

              <Dialog open={open === 'notOkCandidates'} onClose={onClose} fullScreen>
                <ShowNotOkCandidatesOfSync onClose={onClose}/>
              </Dialog>
            </>
            :
            <></>
          }

          {wrongPlacesRgis.length ?
            <>
              <Button onClick={onWrongPlacesOpen}>
                Посмотреть не совпадающие с ргис ограды
              </Button>

              <Dialog open={open === 'wrongPlaces'} onClose={onClose} fullScreen>
                <ShowWrongPlacesRgis onClose={onClose}/>
              </Dialog>
            </>
            :
            <></>
          }

          <Dialog open={open === 'saveFile'} onClose={onClose}>
            <DialogTitle>Загрузить файл с ргис данными</DialogTitle>

            <DialogContent>
              <Stack spacing={1} marginTop={'1rem'}>
                <TextField
                  {...fileRegister('File', {required: true})}
                  size={'small'}
                  type={'file'}
                  label={'Ргис данные'}
                  InputLabelProps={inputLabelProps}
                />

                <div>
                  <Button onClick={fileSubmit(onSaveFile)}> Загрузить </Button>
                  <Button onClick={onClose}> Отмена </Button>
                </div>

              </Stack>
            </DialogContent>
          </Dialog>
        </Stack>
      </CardActions>

    </Card>
  );
};

export default SyncSchemaRgis;