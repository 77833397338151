import React from 'react';
import { Grid } from '@mui/material';
import ImportVectorsRgis from '../../../../components/ImprotVectorsRgis/ImportVectorsRgis';
import { IMPORT_RGIS_URL, IMPORT_VECTORS_URL } from '../../../../api/urls';
import ImportSchemas from "./schemas/ImportSchemas";
import ImportRgisPlacesAndGraves from "../../../../components/ImprotVectorsRgis/ImportRgisPlacesAndGraves";
import ImportPlaceAndGravesFromProject from "../../../../components/ImprotVectorsRgis/ImportPlaceAndGravesFromProject";
import ReplaceSectionOrBorder from "../../../../components/replaceSectionsOrBorders/ReplaceSectionOrBorder";
import ReplacePlaces from "../../../../components/replacePlaces/ReplacePlaces";
import ImportUserMapLayers from "../../../../components/ImportUserMapLayers/ImportUserMapLayers";

const ImportFeatures = () => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <ImportVectorsRgis
          url={IMPORT_VECTORS_URL}
          title={'Первичная инвентаризация (".geojson")'}
        />
      </Grid>

      <Grid item>
        <ImportVectorsRgis
          url={IMPORT_RGIS_URL}
          title={'Повторная инвентаризация (".geojson")'}
        />
      </Grid>

      <Grid item>
        <ImportRgisPlacesAndGraves/>
      </Grid>

      <Grid item>
        <ImportSchemas/>
      </Grid>

      <Grid item>
        <ImportPlaceAndGravesFromProject/>
      </Grid>

      <Grid item>
        <ReplaceSectionOrBorder type={'section'}/>
      </Grid>

      <Grid item>
        <ReplaceSectionOrBorder type={'border'}/>
      </Grid>

      <Grid item>
        <ReplacePlaces/>
      </Grid>

      <Grid item>
        <ImportUserMapLayers/>
      </Grid>

      {/*<Grid item>*/}
      {/*  <ImportFromRgis/>*/}
      {/*</Grid>*/}
    </Grid>
  );
};

export default ImportFeatures;