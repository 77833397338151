import { axiosInstance } from '../axios';
import {
  CHECK_RGIS_FILE_URL,
  GET_ALL_DELETED_PROJECTS_URL,
  GET_DATA_FOR_DECODE_URL,
  GET_DATA_FOR_MAP_URL,
  GET_GRAVE_URL,
  GET_GRAVES_URL,
  GET_PLACE_URL,
  GET_PLACES_URL,
  GET_PLANNED_PLACE_BY_ID_URL,
  GET_PLANNED_PLACES_WITH_SEARCH_URL,
  GET_PROJECT_WITHOUT_SCHEMA_DATA_URL,
  GET_PROJECTS_BY_MUNICIPALITY_URL,
  GET_PROJECTS_URL,
  GET_PROJECTS_WITH_PERMISSIONS_URL,
  GET_USER_DECODE_HISTORY_URL, GET_USER_MAP_LAYERS_URL
} from '../urls';
import { IProject } from '../../models/IProject';
import { ls_getProjectInfo } from '../../helpers/localStorage';
import { getMonumentTypes } from '../grave/graveApi';
import { SortType } from '../../models/ICurrentDecodePage';
import {IUserMapLayer} from "../../models/IUserMapLayer";

export const getProjects = () => {
  return axiosInstance.get<IProject[]>(GET_PROJECTS_URL)
}

export const getProjectsWithPermissions = async () => {
  const response = await axiosInstance.get<IProject[]>(GET_PROJECTS_WITH_PERMISSIONS_URL)
  return response.data
}

export const getDeletedProjects = () => {
  return axiosInstance.get<IProject[]>(GET_ALL_DELETED_PROJECTS_URL)
}

export const getGraves = (offset: number, count: number, searchParams: any, sortedParams: any) => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_GRAVES_URL + `?schema=${schema}&offset=${offset}&count=${count}&search=${JSON.stringify(searchParams)}&sortedName=${JSON.stringify(sortedParams)}`)
}

export const getGraveById = (id: number) => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_GRAVE_URL + id + `?schema=${schema}`)
}

export const checkExistingRgisFile = (schema: string | undefined) => {
  if (!schema) {
    return
  }

  return axiosInstance.get(CHECK_RGIS_FILE_URL + `?schema=${schema}`)
}

export const getPlaces = (offset: number, count: number, searchParams: any, sortedParams: any, type?: 'common' | 'planned') => {
  const schema = ls_getProjectInfo().schema
  const url = type === 'common' ? GET_PLACES_URL : GET_PLANNED_PLACES_WITH_SEARCH_URL
  return axiosInstance.get(url + `?schema=${schema}&offset=${offset}&count=${count}&search=${JSON.stringify(searchParams)}&sortedName=${JSON.stringify(sortedParams)}`)
}

export const getPlaceById = (id: number, type: 'common' | 'planned' | undefined = 'common') => {
  const schema = ls_getProjectInfo().schema
  const url = type === 'planned' ? GET_PLANNED_PLACE_BY_ID_URL : GET_PLACE_URL
  return axiosInstance.get(url + id + `?schema=${schema}`)
}

export const getDataForMap = () => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get(GET_DATA_FOR_MAP_URL + schema)
}

export const getUserMapLayers = () => {
  const schema = ls_getProjectInfo().schema
  return axiosInstance.get<IUserMapLayer[]>(GET_USER_MAP_LAYERS_URL + `?schema=${schema}`);
}

export const getDecodeData = async (sortType: SortType) => {
  const sortTypeMap = {
    default: 'placeNumber',
    isProcessed: 'isProcessed'
  }

  const schema = ls_getProjectInfo().schema
  const resDecodeData = await axiosInstance.get(GET_DATA_FOR_DECODE_URL + `?schema=${schema}&sort=${sortTypeMap[sortType]}`)
  const resHistoryUser = await axiosInstance.get(GET_USER_DECODE_HISTORY_URL)
  const resGraveTypes = await getMonumentTypes()

  return {
    decodeData: resDecodeData.data.places,
    graveTypes: resGraveTypes.data.types,
    history: resHistoryUser.data.history
  }
}

export const getProjectWithoutSchema = async () => {
  const response = await axiosInstance.get(GET_PROJECT_WITHOUT_SCHEMA_DATA_URL)

  return response.data
}

export const getProjectByMunicipality = async () => {
  const response = await axiosInstance.get(GET_PROJECTS_BY_MUNICIPALITY_URL)

  return response
}