import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getPlaceById } from '../../../../api/project/projectApi';
import { getPlaceSections, getPlaceStatuses, getPlaceTypes } from '../../../../api/place/placeApi';
import { useRecoilState } from 'recoil';
import { placeStateAtom } from '../../../../store/projectStore';
import { placeDto } from '../usePlaces';
import { useCustomMutation } from '../../../../api/useCustomMutation';
import { UPDATE_PLACE_URL, UPDATE_PLANNED_PLACE_BY_ID_URL, WRITE_USER_HISTORY_URL } from '../../../../api/urls';
import { toast } from 'react-toastify';
import { ls_getProjectInfo } from '../../../../helpers/localStorage';
import { validateDatesInObject } from '../../../../helpers/validateDatesInObject';
import { NO_INFO } from '../../../../utils/utils';
import { useNavigate } from "react-router-dom";
import { showInfoField } from "../../../../helpers/showInfoField";
import { changeWidthOrLength } from "../../../../helpers/changeCoordinates";
import { area, polygon } from "@turf/turf";

const useMapPlace = (id: number, type: 'common' | 'planned' = 'common') => {
  const cacheTime = 1000 * 60 * 20
  const mutation = useCustomMutation()
  const [placeState, setPlaceState] = useRecoilState(placeStateAtom)
  const projectInfo = ls_getProjectInfo()
  const navigate = useNavigate()

  const cancel = () => navigate(-1)

  const {
    data: placeResponse,
    refetch: placeRefetch,
    isLoading: placeIsLoading,
  } = useQuery([`place/${id}`, id, type], () => getPlaceById(id, type))

  const {
    data: placeTypesResponse,
  } = useQuery(['placeTypes'], getPlaceTypes, { cacheTime, staleTime: cacheTime })

  const {
    data: placeStatusesResponse,
  } = useQuery(['placeStatuses'], getPlaceStatuses, { cacheTime, staleTime: cacheTime })

  const {
    data: placeSectionsResponse,
  } = useQuery(['placeSections'], getPlaceSections, { cacheTime, staleTime: cacheTime })

  const searchDirty = (data: any) => {
    let dirty = {} as any
    for (const { fieldName, type } of placeDto) {
      if (type === 'bool') {
        if (showInfoField(data[fieldName]) !== showInfoField(placeResponse?.data.place[fieldName])) {
          dirty[fieldName] = true
        }
        continue
      }

      if (fieldName === 'geometry') {
        continue
      }

      if (data[fieldName] !== showInfoField(placeResponse?.data.place[fieldName], { dateWithoutTime: true })) {
        dirty[fieldName] = true
      }
    }

    return dirty;
  }

  const onSubmit = async (data: any) => {
    const dateKeys = ['responsibleDob']
    const numberKeys = [
      'placeNumber',
      'idPlaceRgis',
      'responsiblePassportSeries',
      'responsiblePassportNumber',
      'wallNumber', 'nicheNumber',
      'fenceHeight',
      'length',
      'width'
    ]

    let needChangeCoordinates = true

    const dirty = searchDirty(data)

    for (const { fieldName } of placeDto) {
      if (!dirty[fieldName] && fieldName !== 'id') {
        delete data[fieldName];
        continue;
      }

      if (data[fieldName] === NO_INFO) {
        data[fieldName] = null
      }
    }

    for (const [key] of Object.entries(data)) {
      if (numberKeys.includes(key)) {
        if (data[key] === '') {
          data[key] = null;
        } else {
          data[key] = Number(data[key]);
        }
      }
    }

    if (data['length'] && data['width']) {
      if (placeResponse?.data.place.geometry && placeResponse?.data.place.length !== data['length'] && placeResponse?.data.place.width !== data['width']) {
        const {geometry} = placeResponse.data.place

        if (data['length'] < data['width']) {
          const oldLength = data['length']
          data['length'] = data['width']
          data['width'] = oldLength
        }

        const coordinates = changeWidthOrLength('all', {
          length: data['length'],
          width: data['width']
        }, geometry.coordinates[0])

        data.geometry = {
          ...placeResponse.data.place.geometry,
          coordinates
        }

        data['area'] = Number(area(polygon(coordinates)).toFixed(2))
      }

      needChangeCoordinates = false
    }

    if (data['length'] && needChangeCoordinates) {
      if (placeResponse?.data.place.geometry && placeResponse?.data.place.length !== data['length']) {
        const {geometry} = placeResponse.data.place

        if (geometry.coordinates[0].length !== 5) {
          toast.error('Нельзя изменить длину у не четырёхугольной ограды');
          return
        }

        const coordinates = changeWidthOrLength('length', data['length'], geometry.coordinates[0])

        data.geometry = {
          ...placeResponse.data.place.geometry,
          coordinates
        }

        if (data['length'] < placeResponse?.data.place.width) {
          const oldLength = data['length']
          data['length'] = placeResponse?.data.place.width
          data['width'] = oldLength
        }

        data['area'] = Number(area(polygon(coordinates)).toFixed(2))
      }
    }

    if (data['width'] && needChangeCoordinates) {
      if (placeResponse?.data.place.geometry && placeResponse?.data.place.length !== data['width']) {
        const {geometry} = placeResponse.data.place

        if (geometry.coordinates[0].length !== 5) {
          toast.error('Нельзя изменить ширину у не четырёхугольной ограды')
          return
        }

        const coordinates = changeWidthOrLength('width', data['width'], geometry.coordinates[0])

        data.geometry = {
          ...placeResponse.data.place.geometry,
          coordinates
        }

        if (placeResponse?.data.place.length < data['width']) {
          const oldLength = placeResponse?.data.place.length
          data['length'] = data['width']
          data['width'] = oldLength
        }

        data['area'] = Number(area(polygon(coordinates)).toFixed(2))
      }
    }

    if (data.placeStatus) {
      placeStatusesResponse?.data.statuses.forEach((status: any) => {
        if (data.placeStatus === status.name) {
          data.idPlaceStatus = status.id
        }
      })
    }

    if (data.placeType) {
      placeTypesResponse?.data.types.forEach((type: any) => {
        if (data.placeType === type.name) {
          data.idPlaceType = type.id
        }
      })
    }

    if (data.sectionNumber) {
      placeSectionsResponse?.data.sections.forEach((section: any) => {
        if (data.sectionNumber === section.sectionNumber) {
          data.idSection = section.id
        }
      })
    }

    validateDatesInObject(data, dateKeys)

    data.schema = projectInfo.schema

    let changesDescription = {
      action: 'edit',
      objectId: data.id,
      data: []
    } as any

    for (const [key, _] of Object.entries(dirty)) {
      changesDescription.data.push({
        fieldName: key,
        oldData: placeResponse?.data.place[key],
        newData: data[key]
      })
    }

    if (!Object.entries(changesDescription.data).length) {
      toast.error('Вы ничего не изменяли')
      return
    }

    try {
      const url = type === 'common' ? UPDATE_PLACE_URL : UPDATE_PLANNED_PLACE_BY_ID_URL

      const { data: editData } = await mutation.mutateAsync({
        method: 'patch',
        data: data,
        url: url + data.id,
      })

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: projectInfo.schema,
          changesDescription,
          actionObject: type === 'common' ? 'place' : 'planned_place'
        },
        url: WRITE_USER_HISTORY_URL
      })

      toast.success(editData.message)
      await placeRefetch()

    } catch (e: any) {
      console.log(e)

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  useEffect(() => {
    if (placeStatusesResponse && placeTypesResponse && placeResponse) {
      setPlaceState({
        placeStatus: placeStatusesResponse?.data.statuses,
        placeType: placeTypesResponse?.data.types,
        section: placeSectionsResponse?.data.sections
      })
    }
  }, [placeStatusesResponse, placeTypesResponse, placeSectionsResponse])

  return {
    isLoading: placeIsLoading,
    place: placeResponse?.data.place,
    placeState,
    onSubmit,
    placeRefetch,
    cancel
  }
};

export default useMapPlace;